import React, { SyntheticEvent } from 'react'

import PageLink from 'Components/PageLink'

import { SVGTickTiny } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import btnCss from 'Components/Button/Button.scss'
import cl from './RegistrationTab.scss'
import chk from 'Scss/pages/checkout.scss'
import lgntb from 'Components/Tabs/LoginTab.scss'
import SocialLoginButton from 'Components/SocialLoginButton'
import Button from 'Components/Button'
import ExternalLink from 'Components/ExternalLink'
import Checkbox from 'Components/Checkbox'
import Input from 'Components/Input'
import Form from 'Components/Form'
import { DispatchProp, connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { checkEmail, registrationError, register } from 'Utils/redux/actions'
import { noError, validateForm, hasErrors } from 'Utils/helpers/errorHelper'
import { RegistrationDataInput } from 'Utils/api/gql/types';
import GoodIdButton from 'Components/GoodIdButton';

type StateType = {
  email: string
  name: string
  password: string
  password_confirm: string
  accept_gdpr: boolean
  regFormExpanded: boolean
  [key: string]: React.ReactNode
}

type ReduxProps = {
  session: Redux.IStoreSessionData
  deliveryAddress: Redux.IDeliveryAddress | undefined
}

type PropType = {
  onContinue: () => void
} & ReduxProps & DispatchProp

class RegistrationTeaserTab extends React.Component<PropType, StateType> {
  state = {
    email: '',
    name: '',
    password: '',
    password_confirm: '',
    accept_gdpr: false,
    regFormExpanded: false
  }

  debouncedCheckEmail = debounce(() => {
    this.props.dispatch(checkEmail(this.state.email))
  }, 600)

  handleChange = (name: string, value: string | boolean) => {
    const { registrationErrors } = this.props.session
    this.props.dispatch(registrationError({ ...registrationErrors, [name]: undefined }))    // reset errors if field changed

    if (name === 'email') {
        this.debouncedCheckEmail()
    }

    this.setState({ [name]: value })
  }

  onRegisterClick = () => {
    const { name, email, password, password_confirm, accept_gdpr } = this.state
    const errors = this.props.session.registrationErrors
    const regData: RegistrationDataInput = {
        name,
        email,
        password
    }
    const mandatoryFields: Array<keyof Redux.RegistrationForm> = ['name', 'email', 'password', 'password_confirm']
    const localErrors: Redux.IFormErrors<Redux.RegistrationForm> = validateForm(this.state, mandatoryFields)

    if (password !== password_confirm) {
        localErrors.fieldErrors.password_confirm = 'A megadott jelszavak nem egyeznek!'
    }

    if (!accept_gdpr) {
        localErrors.fieldErrors.accept_gdpr = 'A regisztrációhoz a feltételek elfogadása szükséges!'
    }

    if (hasErrors(localErrors)) {
        this.props.dispatch(registrationError({ ...errors, ...localErrors }))
        return
    }

    this.props.dispatch(register(regData, password))
  }

  handleShowRegForm = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    this.setState({ regFormExpanded: true })
  }

  render() {
    const { regFormExpanded } = this.state
    return (
      <div className={cn(cg.gridContainer, lgntb.loginTab)}>
        <div>
          {/* TODO: Inline style is because of: https://gitlab.big.hu/groby/webshop-next/merge_requests/89/diffs#93698b7ec6d6fa326309e47ee76bd2d90664a46c */}
          { !regFormExpanded
            ?
              <a className={cn(btnCss.button, btnCss.expanded, btnCss.buttonHasMarginBottom)} style={{ marginTop: 0 }} onClick={this.handleShowRegForm}>
                Regisztrálok az oldalra
              </a>
            : <div className={cn(cg.gridContainer, cl.registrationTab)}>
              <fieldset>
                <Form
                    values={this.state}
                    onChange={this.handleChange}
                    errors={this.props.session.registrationErrors}
                >
                  <Input name="name" label="Név *" type="text" autoComplete="name" placeholder="Név *" required />
                  <Input name="email" label="E-mail cím *" type="email" autoComplete="email" placeholder="E-mail cím *" required />
                  <Input label="Jelszó *" type="password" name="password" autoComplete="new-password" placeholder="Jelszó *" required />
                  <Input name="password_confirm" label="Jelszó megerősítése *" type="password" autoComplete="new-password" placeholder="Jelszó megerősítése *" required />

                  <Checkbox
                    label={
                        <>
                            <span>Kijelentem, hogy az </span>
                            <ExternalLink href="/cikk/aszf">
                                Általános Szerződési Feltételeket
                            </ExternalLink>
                            <span> és az </span>
                            <ExternalLink href="/cikk/adatkezelesi-tajekoztato">
                                Adatkezelési tájékoztatót
                            </ExternalLink>
                            <span> megismertem és elfogadom, s abban szereplő adatkezeléshez hozzájárulok.</span>
                        </>
                    }
                    name="accept_gdpr"
                  />
                </Form>
                <div className={cl.buttonContainer}>
                  <Button className={cn(btnCss.expanded, btnCss.secondary)} label="Regisztráció" disabled={this.props.session.isFetching} onClick={this.onRegisterClick} />
                </div>
              </fieldset>

              <SocialLoginButton type='facebook:reg' />
              <SocialLoginButton type='google:reg' />
            </div>}

        </div>

        <ul className={chk.checkoutPage__ul}>
          <li className={cg.gridX}>
            <div className={cn(cg.cell, cg.shrink, chk.li__iconWrapper)} aria-hidden={true}>
              <SVGTickTiny />
            </div>

            <div className={cn(cg.cell, cg.auto)}>
              Mentett bevásárlólisták
            </div>
          </li>

          <li className={cg.gridX}>
            <div className={cn(cg.cell, cg.shrink, chk.li__iconWrapper)} aria-hidden={true}>
              <SVGTickTiny />
            </div>

            <div className={cn(cg.cell, cg.auto)}>
              Korábbi rendelések nyomonkövetése
            </div>
          </li>

          <li className={cg.gridX}>
            <div className={cn(cg.cell, cg.shrink, chk.li__iconWrapper)} aria-hidden={true}>
              <SVGTickTiny />
            </div>

            <div className={cn(cg.cell, cg.auto)}>
              Értesítéskérés lehetősége leárazásakról és akciókról
            </div>
          </li>

          <li className={cg.gridX}>
            <div className={cn(cg.cell, cg.shrink, chk.li__iconWrapper)} aria-hidden={true}>
              <SVGTickTiny />
            </div>

            <div className={cn(cg.cell, cg.auto)}>
              Termékértékelés leadása
            </div>
          </li>
        </ul>

        <div className={chk.checkoutPage__or}>
          <span>vagy</span>
        </div>

        <div className={lgntb.buttonContainer}>
          <Button
            onClick={this.props.onContinue}
            className={cn(btnCss.button, btnCss.secondary, btnCss.expanded)}
            label="Folytatás regisztráció nélkül"
          />
        </div>

        <div className={lgntb.buttonContainer}>
          <GoodIdButton />
        </div>

      </div>
    )
  }
}

export default connect(({ session, deliveryAddress }: Redux.IReduxState) => ({
  session,
  deliveryAddress
}))(RegistrationTeaserTab)
