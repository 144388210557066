import React from 'react'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Tabs.scss'

interface ITab {
  id?: number
  render: JSX.Element,
  label: string
}

type PropType = {
  defaultActive?: number
  tabs: Array<ITab>
  onTabChange?: (id?: number) => void
  className?: string
}

type StateType = {
  activeTab: number
}

/* TODO: ARIA https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html */
class Tabs extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props)
    this.state = {
      activeTab: props.defaultActive || 0
    }
  }

  handleTabChangeFactory = (i: number) => () => {
    this.setState({ activeTab: i })
    this.props.onTabChange && this.props.onTabChange(this.props.tabs[i].id)
  }

  render() {
    const { activeTab } = this.state
    const { tabs } = this.props

    return (
      <div className={`${cl.tabs} ${this.props.className}`} role="tablist" aria-label="Belépés / Regisztráció">
        <div className={cl.tabsControl}>
          <div className={cn(cg.gridX, cg.alignCenter)}>
            {tabs.map((tab: ITab, i: number) => (
              <div className={cn(cg.cell, cg.small6, cg.mediumShrink, cl.cell, cl.shrink)} key={`tab-control-${i}`}>
                <button
                  className={cn(cl.tabsControl__button, {[cl.tabsControl__buttonIsActive]: activeTab === i})}
                  onClick={this.handleTabChangeFactory(i)}
                >
                  {tab.label}
                </button>
              </div>
            ))}
          </div>
        </div>

        {tabs.map(({ render }: ITab, i: number) => (
          <div className={activeTab === i ? cn(cl.tabsPanel, cl.tabsPanelIsActive) : cl.tabsPanel} key={`tab-component-${i}`}>
            {render}
          </div>
        ))}
      </div >
    )
  }
}

export default Tabs
