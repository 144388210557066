/* eslint-disable */

export const FBLogin = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(function(response){
      if (response.status === 'connected') {
        resolve(response.authResponse.accessToken)
      }

      reject('Facebook login failed.')
    }, {scope: 'public_profile,email'})
  })
}
