import React from 'react'
import Head from 'next/head'
import AppBar from 'Components/AppBar'
import { Tabs, LoginTab, RegistrationTab, SocialLogin } from 'Components/Tabs'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { AppContext } from 'Utils/withReduxStore'
import { redirect } from 'Utils/helpers/routerHelper'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import appBar from 'Components/AppBar/AppBar.scss'
import Footer from 'Components/Footer'
import animateScrollTo from 'animated-scroll-to'
import { goBackSafe, pushRouteSafe, redirectSafe } from 'Utils/redux/actions'
import LoadingPage from 'Components/LoadingPage'
import { getCookie, setCookie } from 'Utils/cookie';
import { REDIRECTBACK_COOKIE } from 'Utils/helpers/constants';

type PropType = {
    page: number
    session: Redux.IStoreSessionData
    dispatch: Dispatch
}

const PAGE_LOGIN = 0
const PAGE_REGISTER = 1

class LoginPage extends React.Component<PropType> {
    tabs: Tabs | null = null

    static async getInitialProps({ asPath, res, initialState: { session } }: AppContext) {
        if (session.loggedIn) {
            redirect('/profil', res)
            return {}
        }

        const page = asPath.indexOf('regisztracio') !== -1
            ? PAGE_REGISTER
            : PAGE_LOGIN

        return { page }
    }

    componentDidMount() {
        animateScrollTo(-100) // valamiert 0-val kicsit lejjebb scrolloz, mint kene
        if (this.props.session.loggedIn) {
            this.redirectBack()
        }
    }

    componentDidUpdate() {
        if (this.props.session.loggedIn) {
            this.redirectBack()
        }
    }

    redirectBack = () => {
        const redirectBackPath = getCookie(REDIRECTBACK_COOKIE)
        if (redirectBackPath) {
            this.props.dispatch(redirectSafe(redirectBackPath || '/profil'))
            setCookie(REDIRECTBACK_COOKIE, '')
        } else {
            this.props.dispatch(redirectSafe('/'))
        }
    }

    handleTabChange = (id?: number) => {
        switch (id) {
            case PAGE_LOGIN:
                this.props.dispatch(pushRouteSafe('/belepes'))
                break
            case PAGE_REGISTER:
                this.props.dispatch(pushRouteSafe('/regisztracio'))
                break
            default:
                break
        }
    }

    render() {
        const { session: { loggedIn } } = this.props
        return (
            <div>
                <Head>
                    <title>{this.props.page === PAGE_LOGIN ? 'Bejelentkezés' : 'Regisztráció'}</title>
                </Head>

                <AppBar
                    pageType="Home"
                    breadcrumb="Home / Login"
                />
                {loggedIn && <LoadingPage />}
                {!loggedIn &&
                    <>
                        <div className={cn(cg.contentHolder)}>
                            <div className={cn(cg.gridContainer, cg.panelLayout, cg.opened)}>
                                <div className={cn(cg.contentPanel, appBar.contentPanel, cg.opened)} style={{marginTop: "40px"}}>
                                    <Tabs
                                        key={this.props.page}
                                        defaultActive={this.props.page}
                                        tabs={[
                                            { id: PAGE_LOGIN, label: 'Bejelentkezés', render: <LoginTab /> },
                                            { id: PAGE_REGISTER, label: 'Regisztráció', render: <RegistrationTab /> }
                                        ]}
                                        onTabChange={this.handleTabChange}
                                    />
                                </div>
                                
                            </div>
                            <SocialLogin />
                        </div>
                        <Footer />
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ session }: Redux.IReduxState) => ({
    session
})

export default connect(mapStateToProps)(LoginPage)
