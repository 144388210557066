import React from 'react'
import Head from 'next/head'

import AppBar from 'Components/AppBar'

import cn from 'classnames'
import cl from 'Scss/pages/_error.scss'
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter'


class LoadingPage extends React.Component<{ renderNothing?: boolean }> {
  componentDidMount () {
    uiEventEmitter.emit(UIEvents.START_LOADING)
  }
  componentWillUnmount() {
    uiEventEmitter.emit(UIEvents.END_LOADING)
  }
  render() {
    if (this.props.renderNothing) {
      return null
    }
    return <>
      <AppBar
        pageType={null}
        breadcrumb="Home"
      />

      <div className={cn(cl.errorPage)}>
        <Head>
          <title>GRoby - az Online Szupermarket - élelmiszer házhozszállítás</title>
        </Head>
      </div>
    </>
  }
}

export default LoadingPage
