import React, { Fragment, SyntheticEvent  } from 'react'
import Button from 'Components/Button'
import ExternalLink from 'Components/ExternalLink'
import SocialLoginButton from 'Components/SocialLoginButton'
import debounce from 'lodash/debounce'
import Checkbox from 'Components/Checkbox'
import Form from 'Components/Form'
import Input from 'Components/Input';
import { checkEmail, registrationError, register } from 'Utils/redux/actions';

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './RegistrationTab.scss'
import ButtonCSS from 'Components/Button/Button.scss'
import { DispatchProp, connect } from 'react-redux';
import { LoginDataInput, RegistrationDataInput } from 'Utils/api/gql/types';
import { validateForm, hasErrors } from 'Utils/helpers/errorHelper';
import GoodIdButton from 'Components/GoodIdButton';

type ReduxProps = {
    isFetching: boolean
    registrationErrors: Redux.IFormErrors<Redux.RegistrationForm>
}

type PropType = ReduxProps & DispatchProp

type StateType = Redux.RegistrationForm

const EMAIL_CHECK_DELAY = 600
const GDPR_FRAGMENT =
    <Fragment>
        <span>Kijelentem, hogy az </span>
        <ExternalLink href="/cikk/aszf">
            Általános Szerződési Feltételeket
        </ExternalLink>
        <span> és az </span>
        <ExternalLink href="/cikk/adatkezelesi-tajekoztato">
            Adatkezelési Tájékoztatót
        </ExternalLink>
        <span> megismertem és elfogadom, az abban szereplő adatkezeléshez hozzájárulok.</span>
    </Fragment>

class RegistrationTab extends React.Component<PropType, StateType> {
    state: StateType = {
        email: '',
        name: '',
        password: '',
        password_confirm: '',
        accept_gdpr: false
    }

    debouncedCheckEmail = debounce(() => {
        this.props.dispatch(checkEmail(this.state.email))
    }, EMAIL_CHECK_DELAY)

    handleChange = (name: keyof Redux.RegistrationForm, value: string | boolean) => {
        const { registrationErrors } = this.props
        if (typeof registrationErrors.fieldErrors[name] !== 'undefined') {
            this.props.dispatch(registrationError({ ...registrationErrors, fieldErrors: { ...registrationErrors.fieldErrors, [name]: undefined }}))    // reset errors if field changed
        }

        if (name === 'email') {
            this.debouncedCheckEmail()
        }

        //@ts-ignore Ezt nem tudom, miert nem veszi be a TS, pedig StateType == RegistrationForm, es name: keyof RegistrationForm
        this.setState({ [name]: value })
    }

    onRegisterClick = () => {
        const { name, email, password, password_confirm, accept_gdpr } = this.state
        const errors = this.props.registrationErrors
        const regData: RegistrationDataInput = {
            name,
            email,
            password
        }
        const mandatoryFields: Array<keyof Redux.RegistrationForm> = ['name', 'email', 'password', 'password_confirm']
        const localErrors: Redux.IFormErrors<Redux.RegistrationForm> = validateForm(this.state, mandatoryFields)

        if (password !== password_confirm) {
            localErrors.fieldErrors.password_confirm = 'A megadott jelszavak nem egyeznek!'
        }

        if (!accept_gdpr) {
            localErrors.fieldErrors.accept_gdpr = 'A regisztrációhoz a feltételek elfogadása szükséges!'
        }

        if (hasErrors(localErrors)) {
            this.props.dispatch(registrationError({ ...errors, ...localErrors }))
            return
        }

        this.props.dispatch(register(regData, password))
    }

    render() {
        return (
            <div className={cn(cg.gridContainer, cl.registrationTab)}>
                <fieldset>
                    <Form
                        values={this.state}
                        onChange={this.handleChange}
                        errors={this.props.registrationErrors}
                    >
                        <Input name="name" label="Név" type="text" autoComplete="name" placeholder="Név" required />
                        <Input name="email" label="E-mail cím" type="email" autoComplete="email" placeholder="E-mail cím" required />
                        <Input name="password" label="Jelszó" type="password"  autoComplete="new-password" placeholder="Jelszó" required />
                        <Input name="password_confirm" label="Jelszó megerősítése" type="password" autoComplete="new-password" placeholder="Jelszó megerősítése" required />
                        <Checkbox label={GDPR_FRAGMENT} name="accept_gdpr" />
                    </Form>
                    <div className={cl.buttonContainer}>
                        <Button className={cn(ButtonCSS.expanded, ButtonCSS.secondary)} label="Regisztráció" disabled={this.props.isFetching || hasErrors(this.props.registrationErrors)} onClick={this.onRegisterClick} />
                    </div>
                </fieldset>
            </div>
        )
    }
}

export default connect<ReduxProps, {}, {}, Redux.IReduxState>(({ session: { registrationErrors, isFetching } }) => ({ registrationErrors, isFetching }))(RegistrationTab)
