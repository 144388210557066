import React from 'react'
import SocialLoginButton from 'Components/SocialLoginButton'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './LoginTab.scss'

import GoodIdButton from 'Components/GoodIdButton';

const SocialLogin: React.FunctionComponent<{ saveGoodIdRedirectBackUrl?: boolean }> = ({ saveGoodIdRedirectBackUrl }) => {

    return (
        <div className={cn(cg.gridContainer)}>
            <div className={cl.socialButtonContainer}>
                <div className={cn(cg.gridX, cg.gridMarginX, cg.textCenter)}>
                    <div className={cn(cg.cell, cg.small12)}>
                        <h3>BELÉPÉS / REGISZTRÁCIÓ KÜLSŐ SZOLGÁLTATÓVAL</h3>
                        <p className={cn(cl.small)}>
                            Amennyiben fiókjait összekapcsolta, kényelmesen bejelentkezhet külső szolgáltatón keresztül.
                        </p>
                    </div>
                </div>
                <div className={cn(cg.gridX, cg.gridMarginX, cg.alignCenter)}>
                    <div className={cn(cg.cell, cg.small12, cg.large4)}>
                        <GoodIdButton saveRedirectBackUrl={saveGoodIdRedirectBackUrl} />
                    </div>
                    <div className={cn(cg.cell, cg.small12, cg.large4)}>
                        <SocialLoginButton type='facebook:login' />
                    </div>
                    <div className={cn(cg.cell, cg.small12, cg.large4)}>
                        <SocialLoginButton type='google:login' />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SocialLogin
