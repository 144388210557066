import React from 'react'
import { connect, DispatchProp } from 'react-redux'
import { FBLogin } from 'Utils/social'

import { login, loginError } from 'Utils/redux/actions'
import { SVGSocialFacebook, SVGGoogleGLogo } from './SVGCollection'
import cn from 'classnames'
import cg from 'Scss/app.scss'
import ButtonCSS from 'Components/Button/Button.scss'
import cl from 'Components/Button/SocialLoginButton.scss'
import { AuthenticationType } from 'Utils/api/gql/types'

type PropType = {
    type: SocialConfigType
}

type SocialConfigType = 'facebook:reg' | 'facebook:login' | 'google:reg' | 'google:login'

interface SocialConfig {
    label: string,
    className: string,
    onClick: () => Promise<any>,
    Icon: JSX.Element
}

type SocialConfigs = {
    [key in SocialConfigType]: SocialConfig
}

class SocialLoginButton extends React.Component<PropType & DispatchProp> {
    private fbLogin = async (isReg = false) => {
        try {
            const socialToken = await FBLogin()
            this.props.dispatch(login('N/A', socialToken, AuthenticationType.facebook, true, isReg))
        } catch (e) {
            this.props.dispatch(loginError({ general: 'Hiba történt a Facebook bejelentkezés közben', fieldErrors: {} }))
        }
    }

  private googleLogin = async (isReg = false) => {
    const scripts = document.getElementsByTagName("script");
    const found = Array.from(scripts).find((s) => s.src.indexOf("https://accounts.google.com/gsi/client") === 0);
    if (found && window.google) {
      window.google.accounts.id.prompt();
      return;
    }
    if (!window.google) {
      const js = document.createElement("script");
      js.src = "https://accounts.google.com/gsi/client";
      js.defer = true;
      js.async = true;
      js.onload = () => {
        if (!window.google) return;
        window.google.accounts.id.initialize({
          client_id: process.env.GWS_GOOGLE_CLIENT_ID,
          callback: (response: { credential: string }) => this.props.dispatch(
            login("N/A", response.credential, AuthenticationType.google, true, isReg)
            ),
        });
        window.google.accounts.id.prompt();
      };
      if (!scripts[0].parentNode) return;
      scripts[0].parentNode.insertBefore(js, scripts[0]);
    }
  };

    private socialConfigs: SocialConfigs = {
        'facebook:reg': {
            label: 'Regisztráció Facebook fiókkal',
            className: ButtonCSS.facebook,
            onClick: () => this.fbLogin(true),
            Icon: <SVGSocialFacebook width={40} height={40} />
        },
        'facebook:login': {
            label: 'Bejelentkezés Facebook fiókkal',
            className: ButtonCSS.facebook,
            onClick: () => this.fbLogin(false),
            Icon: <SVGSocialFacebook width={40} height={40} />
        },
        'google:reg': {
            label: 'Regisztráció Google fiókkal',
            className: ButtonCSS.google,
            onClick: () => this.googleLogin(true),
            Icon: <SVGGoogleGLogo width={40} height={40} />
        },
        'google:login': {
            label: 'Bejelentkezés Google fiókkal',
            className: ButtonCSS.google,
            onClick: () => this.googleLogin(false),
            Icon: <SVGGoogleGLogo width={40} height={40} />
        }
    }

    render = () => {
        const socialConfig = this.socialConfigs[this.props.type]

        if (!socialConfig) {
            throw new Error(`SocialLoginButton: Invalid social config type (types: ${Object.keys(this.socialConfigs).join(', ')})`)
        }

        const { Icon, label, onClick, className } = socialConfig

        return (
            <button className={`${ButtonCSS.button} ${cl.socialLoginButton} ${className}`} onClick={onClick}>
                <span className={cn(cg.gridX, cg.alignMiddle)}>
                    <span className={cn(cg.cell, cg.shrink, cl.iconWrapper)}>
                        {Icon}
                    </span>

                    <span className={cn(cg.cell, cg.auto)}>
                        {label}
                    </span>
                </span>
            </button>
        )
    }
}

export default connect()(SocialLoginButton)
