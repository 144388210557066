import React from 'react'
import Button from 'Components/Button'
import Checkbox from 'Components/Checkbox'
import Input from 'Components/Input';
import Form from 'Components/Form';
import { loginError, login } from 'Utils/redux/actions'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './LoginTab.scss'
import ButtonCSS from 'Components/Button/Button.scss'
import { connect, DispatchProp } from 'react-redux';
import PageLink from 'Components/PageLink'
import { AuthenticationType } from 'Utils/api/gql/types'
import { validateForm, hasErrors } from 'Utils/helpers/errorHelper'


type ReduxProps = {
    session: Redux.IStoreSessionData
}

type OwnProps = {
}

type PropType = OwnProps & ReduxProps & DispatchProp

type StateType = Redux.LoginForm

class LoginTab extends React.Component<PropType, StateType> {
    state = {
        email: '',
        password: '',
        stayLoggedIn: false
    }

    handleChange = (name: keyof Redux.LoginForm, value: string | boolean) => {
        const { dispatch, session: { loginErrors } } = this.props
        if (loginErrors.fieldErrors[name]) {
            dispatch(loginError({ ...loginErrors, fieldErrors: { ...loginErrors.fieldErrors, [name]: undefined } }))  // reset errors if input changed
        }

        //@ts-ignore Ugyanaz mint RegistrationTab-nal
        this.setState({ [name]: value })
    }

    handleLoginClick = () => {
        const { email, password, stayLoggedIn } = this.state
        const errors = validateForm(this.state, ['email', 'password'])
        if (hasErrors(errors)) {
            this.props.dispatch(loginError(errors))
        } else {
            this.props.dispatch(login(email, password, AuthenticationType.password, stayLoggedIn))
        }
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            this.handleLoginClick()
        }
    }

    render = () => {
        const error = {...this.props.session.loginErrors}
        error.generalErrorType = 'inverse'
        return (
        <div className={cn(cg.gridContainer, cl.loginTab)}>
            <fieldset>
                <Form<Redux.LoginForm> onChange={this.handleChange} values={this.state} errors={error}>
                    <Input name="email" label="E-mail cím" type="email" autoComplete="email" placeholder="E-mail cím" onKeyDown={this.handleKeyDown} />
                    <Input name="password" label="Jelszó" type="password" autoComplete="current-password" placeholder="Jelszó" onKeyDown={this.handleKeyDown} />
                    <div className={cn(cg.gridX, cg.alignCenter)}>
                        <div className={cn(cg.cell, cg.shrink)} style={{width: '148px'}}>
                            <Checkbox
                                label="Belépve maradok"
                                name="stayLoggedIn"
                            />
                        </div>
                    </div>
                    <div className={cl.buttonContainer}>
                        <Button className={cn(ButtonCSS.expanded, ButtonCSS.secondary)} label="Belépés" disabled={this.props.session.isFetching} onClick={this.handleLoginClick} />
                    </div>
                </Form>
            </fieldset>

            <div className={cn(cg.gridX, cg.gridMarginX)}>
                <div className={cn(cg.cell, cg.medium7)}>
                    <div className={cn(cg.gridX, cg.gridMarginX)}>
                        <div className={cn(cg.cell)}>
                            <span style={{fontSize: '12px'}}>Még nincsen fiókod?</span>
                            <span style={{marginLeft: '10px', fontSize: '12px'}}>
                                <PageLink toPage='/regisztracio'className={cn(cg.pageLink)}>{/* TODO: change url in addressbar? */}
                                    Regisztráció
                                </PageLink>
                            </span>
                        </div>
                    </div>

                </div>
                <div className={cn(cg.cell, cg.medium5, cg.mediumTextRight)} style={{fontSize: '12px'}}>
                    <PageLink toPage="/elfelejtett-jelszo" className={cn(cl.showForgotPasswordForm, cg.pageLink)}>
                        Elfelejtett jelszó
                    </PageLink>
                </div>
            </div>
        </div>
    )
    }
}
export default connect<ReduxProps, {}, {}, Redux.IReduxState>(({ session }) => ({ session }))(LoginTab)
