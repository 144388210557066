import React, { useEffect } from 'react'
import cl from './GoodIdButton.scss'
import ExternalLink from 'Components/ExternalLink'
import { setCookie } from 'Utils/cookie';
import { REDIRECTBACK_COOKIE } from 'Utils/helpers/constants';

const GoodIdButton: React.FunctionComponent<{ saveRedirectBackUrl?: boolean }> = ({ saveRedirectBackUrl }) => {
    useEffect(() => {
        (function(d, i) {
            var s, b;
            if (d.getElementById(i)) { return; }
            s = d.createElement('script'); s.id = i;
            s.src = 'https://connect.goodid.net/v1.1/connect.min.js';
            b = d.getElementsByTagName('script')[0]; b.parentNode!.insertBefore(s, b);
        })(document, 'goodid-sdk');
        return () => {
            const e = document.getElementById('goodid-sdk')
            if (e) {
                e.parentNode!.removeChild(e)
            }
        }
    }, [])
    
    const onClick = () => {
        if (saveRedirectBackUrl) {
            setCookie(REDIRECTBACK_COOKIE, window.location.pathname)
        }
    }

    return (
        <div className={cl.goodIdCustomStyles} onClick={onClick}>
            <div className={`${cl.goodidLoginButton} goodid-login-button`} data-size="default" data-endpoint="/goodid-init" data-lang="hu_HU"></div>
            <p>
                Lépj be a mobiloddal egyszerűen és biztonságosan.<br />
                <ExternalLink href="/cikk/goodid">
                    Mi a GoodID?
                </ExternalLink>
            </p>
        </div>
    )
}

export default GoodIdButton
