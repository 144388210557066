import React from 'react'
import Button from 'Components/Button'
import Input from 'Components/Input';
import Form from 'Components/Form';

import { connect, DispatchProp } from 'react-redux';
import { updateCart, updateCartVariables, CartFragment } from 'Utils/api/gql/types'
import { validateForm, hasErrors, noError, parseErrors } from 'Utils/helpers/errorHelper'

import cn from 'classnames'
import cl from './LoginTab.scss'
import cg from 'Scss/app.scss'
import ButtonCSS from 'Components/Button/Button.scss'
import _app from 'Pages/_app';
import { updateCartMutation } from 'Utils/api/gql/mutations/updateCart'
import { setCart, checkEmail, registrationError } from 'Utils/redux/actions';
import { pushRoute } from 'Utils/helpers/routerHelper';
import CheckoutHelper from 'Utils/helpers/checkoutHelper';
import { CheckoutStage } from 'Utils/helpers/constants';
import debounce from 'lodash/debounce';


type ReduxProps = {
    session: Redux.IStoreSessionData
    cart: CartFragment | null
    deliveryAddress: Redux.IDeliveryAddress | undefined
}

type OwnProps = {
}

type PropType = OwnProps & ReduxProps & DispatchProp

type StateType = {
    contact_data: Redux.IContactPerson
    isFetching: boolean
    errors: Redux.IFormErrors<Redux.IContactPerson>
}

class ContactDataTab extends React.Component<PropType, StateType> {
    constructor(props: PropType) {
        super(props)
        
        let initialContactData: Redux.IContactPerson = { contact_email: '', contact_name: '', contact_telephone: '' }
        if (this.props.cart && this.props.cart.contact_data) {
            const { __typename, ...cartContactData } = this.props.cart.contact_data
            initialContactData = cartContactData
        } else if (this.props.session.loggedIn) {
            const { name, email, telephone } = this.props.session.customerData
            initialContactData = {
                contact_email: email,
                contact_name: name,
                contact_telephone: telephone || ''
            }
        }
                
        this.state = {
            contact_data: initialContactData,
            errors: noError(),
            isFetching: false
        }
    }
    
    componentDidUpdate(prevProps: PropType) {
        if (!prevProps.session.loggedIn && this.props.session.loggedIn) {
            const { name, email, telephone } = this.props.session.customerData
            this.setState({ contact_data: {
                contact_email: email,
                contact_name: name,
                contact_telephone: telephone || ''
            }})
        }
    }
    
    checkEmail = debounce((email: string) => {
        this.props.dispatch(checkEmail(email))
    }, 500)
    
    handleChange = (name: keyof Redux.IContactPerson, value: string) => {
        if (name === 'contact_email') {
            this.props.dispatch(registrationError(noError()))
            this.checkEmail(value)
        }
        this.setState(state => ({ 
            contact_data: { ...state.contact_data, [name]: value }, 
            errors: { ...state.errors, fieldErrors: { ...state.errors.fieldErrors, [name]: undefined } }
        }))
    }

    handleSubmitClick = async () => {
        const { contact_data } = this.state
        const errors = validateForm(contact_data, ['contact_email', 'contact_name', 'contact_telephone'])
        if (hasErrors(errors) || this.props.session.registrationErrors.fieldErrors.email) {
            this.setState({ errors })
        } else {
            if (!this.props.cart) {
                throw new Error('No cart')
            }
            this.setState({ isFetching: true })
            try {
                const result = await _app.apolloClient.mutate<updateCart, updateCartVariables>({
                    mutation: updateCartMutation,
                    variables: {
                        input: {
                            id: this.props.cart.id,
                            contact_data
                        }
                    }
                })
                
                if (result.data && result.data.updateCart) {
                    this.props.dispatch(setCart(result.data.updateCart))
                    pushRoute('/megrendeles/kiszallitas')
                }
            } catch (e) {
                const errors = parseErrors<Redux.IContactPerson>(e, 'contact_data')
                this.setState({ errors })
            } finally {
                this.setState({ isFetching: false })
            }
        }
    }

    render = () => {
        return (
        <div className={cn(cl.guestContainer)}>
            <h3 className={cn(cg.textCenter)}>{this.props.session.loggedIn ? 'KAPCSOLATTARTÁSI ADATOK' : 'VENDÉGKÉNT VÁSÁROLOK'}</h3>
            <fieldset>
                <Form<Redux.IContactPerson> onChange={this.handleChange} values={this.state.contact_data} errors={this.state.errors}>
                    <Input name="contact_name" label={this.props.session.loggedIn ? "Név" : "Kapcsolattartó neve"} type="text" autoComplete="name" placeholder="Név" />
                    <Input name="contact_email" label="E-mail cím" type="email" autoComplete="email" placeholder="E-mail cím" error={this.state.errors.fieldErrors.contact_email || this.props.session.registrationErrors.fieldErrors.email} />
                    <Input name="contact_telephone" label="Telefonszám" type="phone" autoComplete="phone" placeholder="30 345 6789" />
                    <div className={cl.buttonContainer}>
                        <Button className={cn(ButtonCSS.expanded, ButtonCSS.secondary)} label={CheckoutHelper.getNextCheckoutButtonText(CheckoutStage.LOGIN, this.props)} disabled={this.state.isFetching} onClick={this.handleSubmitClick} />
                    </div>
                </Form>
            </fieldset>
        </div>)
    }
}

export default connect<ReduxProps, {}, {}, Redux.IReduxState>(({ session, cart, deliveryAddress }) => ({ session, cart, deliveryAddress }))(ContactDataTab)
